import React from "react";
import Postal from "./components/Postal";
import AdComponent from './components/AdComponent';

class App extends React.Component {
    constructor() {
        super();
        this.state = {
            imagenes: [],
            postalCounter: 0,
            cargando: true,
            subreddit: "onlyfanspromo",
            estado: "",
            siguiente: "",
            contador: 0,
            postxpagina: 25,
            buscarPor: "hot",
        };

        this.handleClick = this.handleClick.bind(this);
        this.handleKeyUp = this.handleKeyUp.bind(this);
        this.busqueda = this.busqueda.bind(this);
        this.cambioBuscar = this.cambioBuscar.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
    }

    busqueda(sr = this.state.subreddit, siguiente = "", contador = this.state.contador, buscarx = this.state.buscarPor) {
        if (siguiente === "") {
            contador = 0;
        }

        const url = `https://www.reddit.com/r/${sr}/${buscarx}/.json?limit=${this.state.postxpagina}&${siguiente}&count=${contador}`;

        this.setState({ cargando: true, estado: "...", subreddit: sr });

        fetch(url)
            .then(res => res.json())
            .then(res => {
                const nuevosResultados = res.data.children.reduce((result, id) => {
                    if (id.data.preview) { // Is it an image?
                        result.push({
                            titulo: id.data.title,
                            urlFull: id.data.url,
                            urls: id.data.preview.images[0].resolutions,
                            id: id.data.id,
                            autor: id.data.author,
                            link_publicacion: id.data.permalink,
                        });
                    }
                    return result;
                }, []);

                this.setState(prevState => ({
                    imagenes: [...prevState.imagenes, ...nuevosResultados], // Append new images to the existing ones
                    cargando: false,
                    siguiente: res.data.after,
                    contador: prevState.contador + this.state.postxpagina,
                    postalCounter: prevState.postalCounter + nuevosResultados.length // Update postalCounter here
                }));
            })
            .catch(error => {
                this.setState({ estado: "no encontrado/not found", cargando: false });
            });
    }

    cambioBuscar(buscarx) {
        this.setState({ imagenes: [], contador: 0, postalCounter: 0 }); // Clear images on new search and reset the counter
        this.busqueda(undefined, "", 0, buscarx);
    }

    handleClick() {
        const subReddit = document.getElementById("subreddit").value;
        this.setState({ imagenes: [], contador: 0, postalCounter: 0 }); // Clear images on new search and reset the counter
        this.busqueda(subReddit);
    }

    handleKeyUp(event) {
        if (event.keyCode === 13) {
            event.preventDefault();
            document.getElementById("buscar").click();
        }
    }

    handleScroll() {
        if (
            window.innerHeight + document.documentElement.scrollTop
            === document.documentElement.offsetHeight
        ) {
            if (this.state.siguiente && !this.state.cargando) {
                this.busqueda(this.state.subreddit, `after=${this.state.siguiente}`);
            }
        }
    }

    componentDidMount() {
        this.busqueda(this.state.subreddit);
        window.addEventListener("scroll", this.handleScroll);

        // Google Tag Manager Script
        const script = document.createElement("script");
        script.async = true;
        script.src = `https://www.googletagmanager.com/gtm.js?id=GTM-KTPWJR4X`;
        document.head.appendChild(script);

        // Initialize GTM dataLayer
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'gtm.start': new Date().getTime(),
            event: 'gtm.js',
        });
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    }

    render() {
        let counter = 0; // Initialize counter for rendering

        const postales = this.state.imagenes.flatMap((imagen, index) => {
            counter += 1; // Increment counter for each image
            
            const elements = [<Postal key={imagen.id} imagen={imagen} />];

            // Insert an AdComponent after every 5 images
            if (counter % 5 === 0) {
                elements.push(<AdComponent key={`ad-${counter}`} />);
            }

            return elements;
        });

        return (
            <div>
                {/* NoScript Fallback */}
                <noscript>
                    <iframe
                        src="https://www.googletagmanager.com/ns.html?id=GTM-KTPWJR4X"
                        height="0"
                        width="0"
                        style={{ display: 'none', visibility: 'hidden' }}
                        title="GTM"
                    ></iframe>
                </noscript>
                <div className="top">
                    <div className="logo">
                        <img src="lewdlogo_.png" alt="Logo" width="80" />
                    </div>
                    <div className="recomendados">
                        <button className={this.state.buscarPor === "hot" ? "seleccion" : ""} onClick={() => this.cambioBuscar("hot")}>Hot</button>
                        <button className={this.state.buscarPor === "new" ? "seleccion" : ""} onClick={() => this.cambioBuscar("new")}>New</button>
                    </div>
                </div> 

                <div className="contenedor">
                    {postales}
                </div>

                {this.state.cargando && <h1 className="estado">{this.state.estado}</h1>}

                <footer>
                    <a href="">Copyright © 2024 Lewd Muses</a>
                </footer>
            </div>
        );
    }
}

export default App;
